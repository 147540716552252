/* GRID SETTINGS
  --------------------------------------- */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$grid-gutter-widths: (
  xs: 30px,
  sm: 30px,
  md: 30px,
  lg: 32px,
  xl: 32px
);
$grid-container-margins: (
  xs: 32px,
  sm: 32px,
  md: 48px,
  lg: 64px,
  xl: 64px
);
$base-gutter-heights: (
  xs: 13px,
  md: 14px,
  lg: 16px
);
$base-gutter-scales: (
  xs: .75,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 5
);
$main-sm-columns:           12;
$sidebar-sm-columns:        4;

$container-fluid-max:       1440px;
$container-fluid-max-half:  $container-fluid-max/2;

/* COLORS
  --------------------------------------- */
$body-color:            var(--color-body-text);
$body-bg:               var(--color-body-bg);
$brand-primary:         var(--color-brand-primary);
$brand-secondary:       var(--color-brand-secondary);

$link-color:            var(--link-color);
$link-hover-color:      var(--link-color-hover);
$link-decoration:       none;
$link-hover-decoration: underline;
$link-transition:       all .125s ease-in-out;
$indicator-transition:  all .25s ease-in-out;
$caret-transition:      transform .08s linear;

$black:                 #000;

$border-color:          rgba(25, 25, 25, 0.12);


/* FONTS
  --------------------------------------- */
$font-family-sans:      var(--font-sans);
$font-family-serif:     var(--font-serif);
$font-family-awesome:   var(--font-awesome);
$font-family-mono:      var(--font-mono);
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;             //  16
$font-size-xs:          var(--text-xs);   //  12
$font-size-sm:          var(--text-sm);   //  14
$font-size-lg:          var(--text-lg);   //  18
$font-size-xl:          var(--text-xl);   //  20

/*
$font-size-1xl:         var(--text-1xl);   //  20
$font-size-2xl:         var(--text-2xl);  //  24
*/

$font-size-base-static: 16px;

$h1-font-size:          var(--text-5xl);  //  40
$h2-font-size:          var(--text-4xl);  //  32
$h3-font-size:          var(--text-3xl);  //  28
$h4-font-size:          var(--text-2xl);  //  22
$h5-font-size:          var(--text-xl);   //  20
$h6-font-size:          var(--text-lg);   //  18

$font-weight-normal:    var(--weight-normal);
$font-weight-medium:    var(--weight-medium);
$font-weight-semibold:  var(--weight-semibold);
$font-weight-bold:      var(--weight-bold);
$font-weight-base:      $font-weight-normal;

$display-font-family:   $font-family-base;
$headings-font-family:  $font-family-base;
$headings-font-weight:  var(--weight-semibold);
$headings-line-height:  var(--leading-tight);
$headings-tracking:     var(--tracking-tight);

$border-radius:           var(--border-radius);
$border-radius-md:        var(--border-radius-md);
$border-radius-lg:        var(--border-radius-lg);
$border-radius-elevation: 0;


/* FORM INPUTS
  --------------------------------------- */
$input-font-size:                var(--text-base);
$input-font-weight:              var(--font-normal);
$input-line-height:              1;
$input-height:                   auto;
$input-bg:                       var(--input-bg);
$input-disabled-bg:              var(--grayscale-lightGray-lightest);
$input-color:                    var(--input-color);
$input-border-color:             var(--input-border-color);
$input-border-width:             1px;
$input-box-shadow:               none;
$input-border-radius:            var(--input-border-radius);
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;
$input-focus-box-shadow:         none;
$input-placeholder-color:        var(--input-placeholder);



/* MISC OVERRIDES
  --------------------------------------- */
$navbar-brand-height:               auto;
$navbar-brand-padding-y:            auto;
$nav-link-height:                   auto;
