html {
  font-size: var(--text-base);
  height: 100%;
  scroll-behavior: smooth;
  body {
    line-height: var(--leading-normal);
    background-color: var(--color-body-bg);
    color: var(--color-body-text);
  }
}
a {
  @include transition($link-transition);
  color: var(--link-color);
  &:hover,&:active,&:focus {
    color: var(--link-color-hover);
  }
  &:focus-visible {
    @include focus-outline-radius;
  }
  &#skip-link {
    position:fixed;
    left:-999px;
    top: 0;
    z-index:1001;
    width:1px;
    height:1px;
    overflow:hidden;
    &:active, &:focus, &:focus-visible {
      top:8px;
      left: 8px;
      width: auto;
      height: auto;
      overflow:auto;
    }
  }
}

.overline {
  @include overline;
}
.buttons-list {
  @include buttons-list;
}

img {
  @include img-fluid;
}



.socializer-wrap {
  .socializer {
    &>span {
      margin-right: calc(var(--space-xs)/2);
    }
    a {
      width: auto;
      height: auto;
      @extend .btn;
      @extend .btn-light;
      @extend .btn-icon;
    }
  }
}

/* FONTS
  --------------------------------------- */
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  letter-spacing: $headings-tracking;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,p,ul,ol {
  @include paragraph-spacing;
}

ol,ul {
  &.list-wide {
    li {
      @include paragraph-spacing;
    }
  }
}

