@function hextorgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {

  /* FONTS/TYPE
  --------------------------------------- */
  /* font-family */
    --font-sans: 'Inter','Helvetica Neue',Arial,sans-serif;
    --font-serif: var(--font-sans);
    --font-mono: 'IBM Plex Mono',"Lucida Console", Courier, monospace;
    --font-awesome: 'Font Awesome 6 Pro';
    --font-display: var(--font-sans);

  /* line-height */
    --leading-none: 1;
    --leading-less: .875;
    --leading-tightest: 1.1875;
    --leading-tight: 1.2;
    --leading-normal: 1.5;

  /* letter-spacing */
    --tracking-normal: 0em;
    --tracking-tight: -.025em;
    --tracking-med: .04em;
    --tracking-wide: .1em;
    --tracking-wider: .12em;

  /* font-weight */
    --weight-normal: 400;
    --weight-medium: 500;
    --weight-semibold: 550;
    --weight-bold: 700;

  /* font-size */
    --text-3xs: clamp(8px, calc(8px + (8 - 8) * ((100vw - 375px) / (1400 - 375))), 8px);
    --text-2xs: clamp(9px, calc(9px + (10 - 9) * ((100vw - 375px) / (1400 - 375))), 10px);
    --text-xs: clamp(10px, calc(10px + (12 - 10) * ((100vw - 375px) / (1400 - 375))), 12px);
    --text-sm: clamp(12px, calc(12px + (14 - 12) * ((100vw - 375px) / (1400 - 375))), 14px);
    --text-sm-md: clamp(13px, calc(13px + (15 - 13) * ((100vw - 375px) / (1400 - 375))), 15px);
    --text-md: clamp(14px, calc(14px + (16 - 14) * ((100vw - 375px) / (1400 - 375))), 16px);
    --text-lg: clamp(16px, calc(16px + (18 - 16) * ((100vw - 375px) / (1400 - 375))), 18px);
    --text-xl: clamp(18px, calc(18px + (20 - 18) * ((100vw - 375px) / (1400 - 375))), 20px);
    --text-2xl: clamp(20px, calc(20px + (22 - 20) * ((100vw - 375px) / (1600 - 375))), 22px);
    --text-3xl: clamp(24px, calc(24px + (28 - 24) * ((100vw - 375px) / (1600 - 375))), 28px);
    --text-4xl: clamp(28px, calc(30px + (32 - 28) * ((100vw - 375px) / (1600 - 375))), 32px);
    --text-5xl: clamp(32px, calc(32px + (40 - 32) * ((100vw - 375px) / (1600 - 375))), 40px);
    --text-6xl: clamp(36px, calc(36px + (56 - 36) * ((100vw - 375px) / (1600 - 375))), 56px);
    --text-7xl: clamp(40px, calc(40px + (76 - 40) * ((100vw - 375px) / (1600 - 375))), 76px);
    --text-base: var(--text-md);


  /* COLORS
  --------------------------------------- */
    --color-white:              #fff;
    --color-white-off:          #F0F0EB;
    --color-black:              #000;
    --color-slate-black:        #191919;
    --color-slate-dark:         #4F4F4D;
    --color-slate-light:        #b2b2b2;
    --color-rust:               #D5662A;

    --color-green-light-1:      #A4BF41;
    --color-green-light-2:      #9BB873;
    --color-green-dark-1:       #0E2716;
    --color-green-dark-2:       #367538;
    --color-green-dark-3:       #23401C;

    --color-gray-light:         #F4F4F4;

    --color-brand-primary:      var(--color-green-dark-2);
    --color-brand-secondary:    var(--color-rust);

    --color-status-error:       #FF7523;
    --color-status-info:        #73A5E9;
    --color-status-success:     #71B7A5;
    --color-status-warning:     #EEBE32;

    --color-body-text:          var(--color-slate-black);
    --color-body-text-alt:      var(--color-slate-dark);
    --color-body-bg:            var(--color-white);

    --color-body-hover:         var(--color-white-off);

    --color-overline:           var(--color-slate-dark);
    --color-overline-icon:      var(--color-green-light-1);

    --color-icon:               var(--color-body-text);
    --color-icon-hover:         var(--color-green-dark-2);

    --headline-color:           var(--color-body-text);
    --headline-color-alt:       var(--color-body-text);

    --link-color:               var(--color-brand-primary);
    --link-color-hover:         var(--color-body-text);
    --link-color-active:        var(--link-color-hover);
    --link-color-focus:         var(--link-color-hover);
    --link-color-focus-visible: var(--color-status-success);

    --block-border-color:       var(--color-body-text);

    --basic-border-color:       var(--color-slate-light);

    --slick-dot-color:          var(--color-body-text);

    --alert-link-color:         var(--color-body-text);

    --color-accent:             var(--color-black);
    --color-accent-dark:        var(--color-black);

    --color-portfolio:          var(--color-black);

    --color-btn-outline:        var(--color-slate-black);
    --color-btn-outline-hover:  var(--color-white);

    --article-link-color:       var(--color-black);
    --article-link-color-hover: var(--color-brand-primary);

    --caption-border-color:     var(--color-body-text);
    --blockquote-color:         var(--color-body-text);
    --blockquote-border-color:  var(--color-body-text);

  /* MARGINS/PADDING
  --------------------------------------- */
  /* fluid-spacer */
    --space-xs: clamp(12px, calc(12px + (16 - 12) * ((100vw - 375px) / (1400 - 375))), 16px);
    --space-sm: clamp(24px, calc(20px + (32 - 24) * ((100vw - 375px) / (1400 - 375))), 32px);
    --space-md: clamp(30px, calc(30px + (48 - 30) * ((100vw - 375px) / (1400 - 375))), 48px);
    --space-lg: clamp(50px, calc(50px + (80 - 50) * ((100vw - 375px) / (1400 - 375))), 80px);
    --space-xl: clamp(80px, calc(80px + (128 - 80) * ((100vw - 375px) / (1400 - 375))), 128px);
    --space-xxl: clamp(130px, calc(130px + (160 - 130) * ((100vw - 375px) / (1400 - 375))), 160px);

    --space-block: clamp(64px, calc(64px + (120 - 64) * ((100vw - 375px) / (1400 - 375))), 120px);

    --space-base: calc(var(--space-xs)*1.5); // 24


  /* static gaps*/
    --static-size-base: 16px;
    --gap-base: var(--static-size-base);        // 16
    --gap-2xs: calc(var(--gap-base) * .25);     // 4
    --gap-xs: calc(var(--gap-base) * .5);       // 8
    --gap-sm: calc(var(--gap-base) * .75);      // 12
    --gap-md-s: calc(var(--gap-base) * 1.125);  // 18
    --gap-md: calc(var(--gap-base) * 1.25);     // 20
    --gap-lg: calc(var(--gap-base) * 1.5);      // 24
    --gap-lg-xl: calc(var(--gap-base) * 1.875); // 30
    --gap-xl: calc(var(--gap-base) * 2);        // 32
    --gap-2xl: calc(var(--gap-base) * 2.25);    // 36
    --gap-3xl: calc(var(--gap-base) * 3);       // 48
    --gap-64: calc(var(--gap-base) * 4);        // 64
    --gap-80: calc(var(--gap-base) * 5);        // 80
    --gap-96: calc(var(--gap-base) * 6);        // 96
    --gap-120: calc(var(--gap-base) * 7.5);     // 120
    --gap-128: calc(var(--gap-base) * 8);       // 128
    --gap-128: calc(var(--gap-base) * 8);       // 128
    --gap-144: calc(var(--gap-base) * 9);       // 144

    --grid-margin-thin: 2px;


    --border-radius:        8px;
    --border-radius-md:     16px;
    --border-radius-lg:     24px;

  /* INPUTS
  --------------------------------------- */
    --input-color: var(--color-slate-black);
    --input-bg: var(--color-white);
    --input-border-color: var(--color-white);
    --input-border-radius: var(--border-radius-lg);
    --input-text-size: var(--text-base);
    --input-placeholder: #505050;


}
