header.banner {
  border-bottom: 1px solid $border-color;
  .main-header {
    padding-top: var(--space-base);
    padding-bottom: var(--space-base);
  }
  .logo {
    .brand {
      @include link-no-underline;
      @include link-color-reverse;
      img {
        width: 95px;
      }
      span {
        margin-left: var(--gap-base);
        letter-spacing: -0.34px;
        font-size: 17px;
        font-weight: var(--weight-bold);
        line-height: var(--leading-tight);
      }
    }
  }
  .primary-links {
    nav.nav-primary {
      li:not(.button) {
        margin-right: var(--space-sm);
        @include media-breakpoint-only(lg) {
          margin-right: var(--space-xs);
          font-size: var(--text-sm);
        }
        a {
          @include link-no-underline;
          @include link-color-reverse;
          font-weight: var(--weight-medium);
        }
      }
      li.button {
        margin-right: var(--space-xs);
      }
      li:last-child {
        margin-right: 0;
      }
    }
  }
  nav.nav-primary-mobile {
    ul {
      @include list-normalize;
      margin: var(--gap-base) auto var(--gap-3xl);
      max-width: 420px;
      li:not(.button) {
        a {
          @include link-no-underline;
          @include link-color-reverse;
          font-weight: var(--weight-medium);
          padding: var(--gap-sm);
        }
      }
      li {
        a {
          display: block;
          width: 100%;
          text-align: center;
        }
        &.button {
          margin-top: var(--gap-base);
        }
      }
    }
  }
  .navbar-toggler {
    text-align: center;
    padding: var(--gap-sm);
    line-height: var(--leading-less);
    [class*=fa-] {
      font-size: $font-size-base-static;
      width: $font-size-base-static;
      height: $font-size-base-static;
    }
    .fa-bars {
      display: none;
    }
    &.collapsed {
      .fa-bars {
        display: inline-block;
      }
      .fa-close {
        display: none;
      }
    }
    &:not(.collapsed) {
      --btn-bg: var(--color-slate-black);
      --btn-bg-hover: var(--btn-bg);
      --btn-border-base: var(--color-slate-black);
      --btn-border-hover: var(--btn-bg-hover);

    }
  }
}
.alert-banner {
  padding: var(--gap-sm) 0;
  font-size: var(--text-sm-md);
  .message {
    @include last-child-zero-margin;
    text-align: center;
    line-height: var(--leading-tight);
    a {
      @include link-color-reverse;
      @include link-underline;
    }
  }
}
