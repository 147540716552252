.bg-white-off {
  --color-body-bg: var(--color-white-off);
  --color-icon: var(--color-black);
}
.bg-green-dark-1,.bg-green-dark-2,.bg-green-dark-3 {
  --color-body-text: var(--color-white);
  --color-icon: var(--color-green-light-2);
  --btn-color: var(--color-white);
  --btn-color-hover: var(--color-white);
  --btn-border-base: var(--color-green-light-2);
  --link-color-hover: var(--color-green-light-2);
  --color-body-text-alt:  var(--color-white-off);
}

.bg-black,.bg-slate-black,.bg-slate-dark {
  --color-body-text: var(--color-white);
  --color-body-text-alt: var(--color-white-off);
  --color-overline: var(--color-slate-light);
  --link-color: var(--color-green-light-2);
  --link-color-hover: var(--color-white);

  --btn-color: var(--color-slate-black);
  --btn-color-hover: var(--color-slate-black);
  --btn-bg: var(--color-white);
  --btn-bg-hover: var(--color-green-dark-2);
  --btn-border-base: var(--color-white);

  --color-btn-outline: var(--color-white);
  --article-link-color: var(--color-white);
  --article-link-color-hover: var(--color-green-light-1);
}
.bg-black {
  --color-body-bg: var(--color-black);
}
.bg-slate-black {
  --color-body-bg: var(--color-slate-black);
}
.bg-slate-dark {
  --color-body-bg: var(--color-slate-dark);
}
.bg-green-dark-1 {
  --color-body-bg: var(--color-green-dark-1);
}
.bg-green-dark-2 {
  --color-body-bg: var(--color-green-dark-2);
}
.bg-green-dark-3 {
  --color-body-bg: var(--color-green-dark-3);
  --color-body-hover: var(--color-slate-black);
}

[class*="bg-"] {
  background: var(--color-body-bg);
  color: var(--color-body-text);
}
