:root {
  --btn-color: var(--color-white);
  --btn-color-hover: var(--color-white);
  --btn-color-active: var(--btn-color-hover);
  --btn-color-focus: var(--btn-color-hover);
  --btn-bg: var(--color-slate-black);
  --btn-bg-hover: var(--color-green-dark-2);
  --btn-bg-active: var(--btn-bg-hover);
  --btn-bg-focus: var(--btn-bg-hover);
  --btn-border-base: var(--color-slate-black);
  --btn-border-hover: var(--btn-bg-hover);
  --btn-border-active: var(--btn-border-hover);
  --btn-border-focus: var(--btn-border-hover);
  --btn-border-focus-visible: var(--color-status-success);
}

.btn, li.button.menu-item a {
  @include transition($btn-transition);
  font-size: var(--text-sm);
  font-weight: var(--weight-medium);
  box-shadow: none !important;
  padding: 8px 20px;
  border: 1px solid var(--btn-border-base);
  border-radius: 64px;
  position: relative;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  cursor: pointer;
  @include hover-full {
    color: var(--btn-color-hover);
    background-color: var(--btn-bg-hover);
    border-color: var(--btn-border-hover);
    text-decoration: none;
    [class*=fa-] {
      color: var(--btn-icon-hover);
    }
  }
  &.btn-default {
    --btn-color: var(--color-black);
    --btn-color-hover: var(--color-white);
    --btn-bg: var(--color-white-off);
    --btn-border-base: var(--color-white-off);
  }
  &.btn-light {
    --btn-color: var(--color-black);
    --btn-color-hover: var(--color-white);
    --btn-bg: var(--color-gray-light);
    --btn-border-base: var(--color-gray-light);
  }
  &.btn-icon {
    @include d-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    width: calc(var(--text-base)*2);
    height: calc(var(--text-base)*2);
    [class*=fa-] {
      font-size: var(--text-base);
/*
      width: calc(var(--text-base) * var(--leading-tight));
      line-height: var(--text-base);
*/
      &:before {
        width: 100%;
        display: inline-block;
      }
    }
    &.btn-md {
      padding: .6em .75em;
      [class*=fa-] {
        width: calc(var(--text-xs) * var(--leading-tight));
        line-height: var(--text-xs);
      }
    }
  }

  &.btn-icon-right {
    [class*=fa-] {
      margin-left: var(--gap-sm);
    }
  }
  &.btn-icon-left {
    [class*=fa-] {
      margin-right: var(--gap-sm);
    }
  }

}
.btn-outline,li.button.menu-item.button-outline a {
  --btn-color: var(--color-btn-outline);
  --btn-color-hover: var(--color-btn-outline-hover);
  --btn-bg: transparent;
//     --btn-bg-hover: var(--color-slate-black);
}
.btn-inline {
  --btn-color: var(--color-btn-outline);
  --btn-color-hover: var(--color-btn-outline-hover);
  --btn-bg: transparent;
  --btn-border-base: transparent;
}
