// Based on Bootstrap scss/mixins/_grid-framework.scss
// These mixins override the default Bootstrap mixins of matching name.
// Other mixins from the same file are still needed, so the original file
// is required.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints); //null, -sm, -md, etc.

    @if $columns > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @extend %grid-column;
        }
      }
    }

    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      $gutter_breakpoint: breakpoint-effective( $breakpoint, $grid-gutter-widths );

      .col,
      .col-auto {
        padding-right: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
        padding-left: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
      }

      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding-right: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
        padding-left: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .row-cols#{$infix}-#{$i} {
            @include row-cols($i); // sets the max with and flex size
          }
        }
      }

      .col#{$infix}-auto {
        @include make-col-auto(); // sets width and flex size to auto
        padding-right: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
        padding-left: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
      }
      main.main {
        padding-right: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
        padding-left: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
      }
      @if $columns > 0 {
        %grid-column#{$infix} {
          padding-right: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
          padding-left: map-get($grid-gutter-widths, $gutter_breakpoint) / 2;
        }
        $previous-breakpoints: breakpoints-up-to($breakpoint);
        @each $previous-breakpoint in map-keys($previous-breakpoints) {
          $infix2: breakpoint-infix($previous-breakpoint, $breakpoints); //null, -sm, -md, etc.
          @for $i from 1 through $columns {
            .col#{$infix2}-#{$i} {
              @extend %grid-column#{$infix};
            }
          }
        }

        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns); // sets the max with and flex size
          }
        }
      }

      .order#{$infix}-first { order: -1; }

      .order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} { order: $i; }
      }

      @if $columns > 0 {
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}

// Based on Bootstrap scss/mixins/_grid.scss
// These mixins override the default Bootstrap mixins of matching name.
// Other mixins from the same file are still needed, so the original file
// is required.

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
/*
@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
}
*/
@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: map-get($grid-container-margins, 'xs')/-2;
  margin-left: map-get($grid-container-margins, 'xs')/-2;
  @include media-breakpoint-up(lg) {
    margin-right: map-get($grid-container-margins, 'lg')/-2;
    margin-left: map-get($grid-container-margins, 'lg')/-2;
  }
}



// Name of the previous breakpoint, or null for the first breakpoint.
//
// Similar to Bootstrap's default `breakpoint-next()`.
//
//    >> breakpoint-prev(sm)
//    xs
//    >> breakpoint-prev(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    xs
//    >> breakpoint-prev(sm, $breakpoint-names: (xs sm md lg xl))
//    xs
@function breakpoint-prev($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n != 1, nth($breakpoint-names, $n - 1), null);
}

// Name of the breakpoint in effect at a breakpoint level from a potentially
// incomplete breakpoint map.
//
//    >> breakpoint-effective(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px) )
//    sm
//    >> breakpoint-effective(sm, (xs: 0, md: 768px, lg: 992px, xl: 1200px) )
//    xs
@function breakpoint-effective($name, $examined-breakpoints, $breakpoints: $grid-breakpoints ) {
  $n: index(map-keys($examined-breakpoints), $name);
  @return if($n != null, $name, breakpoint-effective( breakpoint-prev($name, $breakpoints), $examined-breakpoints, $breakpoints ) );
}

@function breakpoints-up-to($name, $breakpoints: $grid-breakpoints) {
  $new-map: ();
  $prev: breakpoint-prev($name, $breakpoints);
  @while $prev != null {
    $new-map: map-merge(( $prev: map-get($breakpoints, $prev) ), $new-map);
    $prev: breakpoint-prev($prev, $breakpoints);
  }
  @return map-merge($new-map, ( $name: map-get($breakpoints, $name) ));
}
// Based on Bootstrap scss/_grid.scss
// This file replaces the original file, so it need to contain both the
// changed and unchanged parts.

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  // Single container class with breakpoint max-widths
  .container,
  // 100% wide container at all breakpoints
  .container-fluid,
  // Breakpoint dependent fluid containers
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl
  {
    @include make-container();
    padding-right: map-get($grid-container-margins, 'xs');
    padding-left: map-get($grid-container-margins, 'xs');
    @include media-breakpoint-down(xs) {
      padding-right: map-get($grid-container-margins, 'xs')/2;
      padding-left: map-get($grid-container-margins, 'xs')/2;
    }


  }

  // Responsive containers that are 100% wide until a breakpoint
  @each $breakpoint, $container-max-width in $container-max-widths {
    $margin_breakpoint: breakpoint-effective( $breakpoint, $grid-container-margins );

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      %responsive-container-#{$breakpoint} {
        max-width: $container-max-width;
        padding-right: map-get($grid-container-margins, $margin_breakpoint);
        padding-left: map-get($grid-container-margins, $margin_breakpoint);
      }

      // Extend each breakpoint which is smaller or equal to the current breakpoint
      $extend-breakpoint: true;

      @each $name, $width in $grid-breakpoints {
        @if ($extend-breakpoint) {
          .container#{breakpoint-infix($name, $grid-breakpoints)},.container-fluid {
            @extend %responsive-container-#{$breakpoint};
          }

          // Once the current breakpoint is reached, stop extending
          @if ($breakpoint == $name) {
            $extend-breakpoint: false;
          }
        }
      }
    }
  }
}


// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();
    margin-right: - map-get($grid-container-margins, 'xs') !important;
    margin-left: - map-get($grid-container-margins, 'xs') !important;
  }

  @each $breakpoint, $width in $grid-breakpoints {
    $margin_breakpoint: breakpoint-effective($breakpoint, $grid-gutter-widths);
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      $gutter_compensation: map-get($grid-gutter-widths, $margin_breakpoint) / 2;
      .row {
        margin-right: - $gutter_compensation !important;
        margin-left: - $gutter_compensation !important;
      }
    }
  }

  .row-fluid {
    margin-right: - map-get($grid-container-margins, 'xs');
    margin-left: - map-get($grid-container-margins, 'xs');
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}



// Columns
//
// 5-column
.col-md-5th-1 {
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col(2.4);
  }
}
.col-lg-5th-1 {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col(2.4);
  }
}
.col-lg-5th-4 {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col(9.6);
  }
}

.col-xl-5th-1 {
  @include make-col-ready();
  @include media-breakpoint-up(xl) {
    @include make-col(2.4);
  }
}

