/* GRID
  --------------------------------------- */
@mixin wp-full-width {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}
@mixin wp-full-width-no-padding {
  @include wp-full-width;
  margin: 0;
}


@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translatex(-144vw); }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--space-base)));
  }
}
/* COLORS
  --------------------------------------- */
@mixin black-trans-50 {
  color: rgba(0,0,0,.5);
}
@mixin black-trans-80 {
  color: rgba(0,0,0,.8);
}
@mixin slate-black-trans-05 {
  background-color: rgba(25, 25, 25,.05);
}
@mixin white-60 {
  color: rgba(256,256,256,.6);
}


/* BLOCK ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  li {
    display: inline-block;
  }
}
@mixin list-normalize-inline-block {
  @include list-normalize;
  &>li {
    display: inline-block;
  }
}
@mixin list-normalize-flexbox {
  @include list-normalize;
  @include d-flex;
  &>li {
    @include d-flex;
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin last-child-inherit-margin {
  &>:last-child {
    margin-bottom: inherit;
  }
}
@mixin last-child-zero-margin-important {
  &>:last-child {
    margin-bottom: 0 !important;
  }
}
@mixin paragraph-spacing {
  margin-bottom: var(--gap-lg);
}
@mixin paragraph-spacing-top {
  margin-top: var(--space-sm);
}
@mixin buttons-list {
  @include list-normalize-inline-block;
//   @include paragraph-spacing;
  li {
    margin: var(--gap-xs) var(--gap-xs) 0 0;
    &+li {
      a.btn-inline {
        margin-left: var(--gap-sm);
      }
    }
  }
}
@mixin list-category {
  @include list-normalize;
  &>li {
    display: inline-block;
    &:not(.cover-story-label) {
      margin: 0 var(--gap-2xs) 0 0;
      padding: var(--gap-2xs) var(--gap-xs);
      font-size: var(--text-xs);
      font-weight: $font-weight-semibold;
      color: var(--color-green-lighter);
      background-color: var(--color-green-faded);
      border: 1px solid var(--color-green-pastel);
      border-radius: $border-radius-sm;
    }
    &.cover-story-label {
      margin: 0 0 0 var(--gap-sm);
      color: var(--grayscale-lightGray);
    }
  }
}
@mixin list-pills {
  @include list-normalize;
  &>li {
    display: inline-block;
    margin: var(--gap-2xs) 0 var(--gap-2xs) var(--gap-xs);
    a {
      @extend .btn;
      &:not(.active) {
        @extend .btn-default;
      }
      font-size: var(--text-sm) !important;
      padding: 4px 12px !important;
    }
    ul {
      display: none;
    }
  }
}



/* LINKS & ACTIONS
  --------------------------------------- */
@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin link-underline-dash {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  @include hover-focus {
    text-decoration: none;
    border-bottom-style: solid;
  }
}
@mixin link-color-reverse {
  color: var(--link-color-hover);
  @include hover-full {
    color: var(--link-color);
  }
}
@mixin link-article-color {
  color: var(--article-link-color);
  @include hover-full {
    color: var(--article-link-color-hover);
  }
}

@mixin link-caret-transition {
  i[class*="fa-"] {
    @include transition($caret-transition);
  }
  &:not(.collapsed) {
    i[class*="fa-"] {
      transform: rotate(180deg);
    }
  }
}

@mixin hover-full {
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    @content;
  }
}
@mixin focus-outline {
  outline: 2px solid var(--link-color-focus-visible);
}
@mixin focus-outline-radius {
  @include focus-outline
  border-radius: 2px;
}
@mixin focus-outline-thin {
  @include focus-outline;
  outline-width: 1px;
  outline-offset: 0px;
}
@mixin tile-hover {
  figure {
    overflow: hidden;
    img {
      will-change: transform, opacity;
      transition: all ease .5s;
    }
  }
  &:hover,&:active,&:focus,&:focus-visible {
    figure {
      img {
        transform: scale(1.05);
      }
    }
  }
}
@mixin tile-grid {
  .hentry {
    margin-bottom: var(--space-md);
    a.article-link, .article-details {
      @include last-child-zero-margin;
      @include link-no-underline;
      @include tile-hover;
      height: 100%;
      flex-direction: column-reverse;
      border-radius: var(--border-radius-lg);
      color: var(--article-link-color);
      font-size: var(--text-sm-md);
      &>figure {
        @include figure-aspect-ratio('3/2');
        border-top-left-radius: var(--border-radius-lg);
        border-top-right-radius: var(--border-radius-lg);
      }
      &>header {
        @include last-child-zero-margin;
        @include d-flex;
        flex-direction: column;
        flex: 1;
        padding: var(--space-base) var(--space-sm) var(--space-sm);
        .category {
          margin-bottom: var(--gap-base);
        }
        .entry-title {
          @include transition($link-transition);
          color: var(--article-link-color);
          margin-bottom: var(--gap-xs);
          font-size: var(--text-2xl);
        }
      }
    }
    a.article-link {
      &:hover,&:active,&:focus,&:focus-visible {
        &>header {
          .entry-title {
            color: var(--article-link-color-hover);
          }
        }
      }
    }
  }
}
@mixin tile-basic {
  @include last-child-zero-margin;
  height: 100%;
  padding: var(--space-md);
  border-radius: var(--border-radius-lg);
  line-height: 1.4;
}


@mixin page-header-multi {
  @include wp-full-width;
  margin-bottom: var(--space-block);
  padding: var(--space-lg) 0;
  &>.container-fluid {
    &>.row {
      align-items: center;
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }
    }
  }
  .details {
    @include last-child-zero-margin;
    @include media-breakpoint-up(lg) {
      padding-right: var(--space-lg);
    }
    .overline {
      margin-bottom: calc(var(--space-sm)*1.25);
      a {
        color: var(--color-overline);
      }
    }
    .entry-title {
      margin-bottom: var(--space-base);
    }
    .entry-excerpt {
      @include last-child-zero-margin;
      color: var(--color-overline);
      font-size: var(--text-xl);
    }
    .entry-meta {
      @include last-child-zero-margin;
      margin-top: var(--space-md);
      color: var(--color-body-text-alt);
      font-size: var(--text-sm);
      .author {
        display: block;
      }
      a {
        color: var(--color-body-text-alt);
      }
    }
  }
  .media {
    figure {
      @include figure-aspect-ratio('3/2');
      margin-bottom: var(--space-md);
      border-radius: var(--border-radius-lg);
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}


/* FONTS
  --------------------------------------- */
@mixin sans-text {
  font-family: var(--font-sans);
}
@mixin mono-text {
  font-family: var(--font-mono);
  font-weight: var(--weight-medium);
}
@mixin fa-text {
  font-family: $font-family-awesome;
}
@mixin overline {
  @include mono-text;
  color: var(--color-overline);
  font-size: var(--text-sm);
  line-height: var(--leading-none);
  position: relative;
  padding-left: calc(var(--text-3xs)*2);
  margin-bottom: var(--gap-base);
  &:before {
    font: var(--fa-font-solid);
    font-size: var(--text-3xs);
    color: var(--color-overline-icon);
    content: "\f111";
    position: absolute;
    left: 0;
    top: calc(var(--text-3xs)*.375);
  }
}

/* IMAGES
  --------------------------------------- */
@mixin svg-color-fill($color) {
  path {
    @include transition($link-transition);
    fill: $color;
  }
}
@mixin figure-aspect-ratio($ratio) {
  aspect-ratio: #{$ratio};
  overflow: hidden;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
}


/* NAVS
  --------------------------------------- */
@mixin social-nav {
  @include list-normalize-flexbox;
  flex-wrap: wrap;
  li {
    margin-right: var(--gap-xs);
    a {
      @extend .btn;
      @extend .btn-social;
    }
  }
}
@mixin slick-nav {
  @include list-normalize;
  @include list-inline-block;
  position: absolute;
  bottom: 0;
  right: map-get($grid-container-margins, 'xs')/2;
  @include media-breakpoint-up(lg) {
    right: map-get($grid-container-margins, 'lg')/2;
  }
}


/* FORMS
  --------------------------------------- */
@mixin select-control($color) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M238 429.3l22.6-22.6 192-192L475.3 192 430 146.7l-22.6 22.6L238 338.7 68.6 169.4 46 146.7 .7 192l22.6 22.6 192 192L238 429.3z" fill="rgb(#{$color})"/></svg>');
  background-size: auto 1em;
  padding-right: 2.75em !important;
  line-height: 1.1875;
}
@mixin checkbox-radio-control {
  display:none;
  &+label {
    &:before {
      font: var(--fa-font-sharp-regular);
      display: inline-block;
      letter-spacing: .5em;
      color: var(--input-bg);
    }
  }
  &:checked {
    &+label {
      &:before {
        font: var(--fa-font-sharp-solid);
        color: var(--color-yellow);
      }
    }
  }
}
@mixin checkbox-control {
  @include checkbox-radio-control;
  &+label {
    &:before {
      content: "\f0c8";
    }
  }
  &:checked {
    &+label {
      &:before {
        content: "\f14a";
      }
    }
  }
}
@mixin radio-control {
  @include checkbox-radio-control;
  &+label {
    &:before {
      content: "\f111";
    }
  }
  &:checked {
    &+label {
      &:before {
        content: "\f058";
      }
    }
  }
}



/* GRID
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}
@mixin container-full-width {
  max-width: none !important;
}
@mixin main-column-width-narrow {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}
@mixin main-column-width-wide {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}
