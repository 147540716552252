// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// IMAGES & VIDEO
.wp-block-image, figure.wp-block-image,.wp-block-embed  {
  @include paragraph-spacing;
  figcaption,.wp-element-caption {
    margin-top: var(--gap-xs);
    margin-bottom: 0;
    padding: 0 0 0 var(--gap-xs);
    border-left: 2px solid var(--caption-border-color);
    line-height: var(--leading-normal);
    font-size: var(--text-sm);
  }
}
.wp-block-image figure, figure.wp-block-image {
  margin: var(--space-md) 0;
  display: table;
  figcaption {
    display: table-caption;
    caption-side: bottom;
  }
}

// BLOCKQUOTES
blockquote.wp-block-quote,figure.wp-block-pullquote {
  margin-top: var(--space-base);
  margin-bottom: var(--space-sm);
  @include last-child-zero-margin;
  color: var(--blockquote-color);
  border-left: 2px solid var(--blockquote-border-color);
  padding-left: var(--space-base);
  font-size: var(--text-xl);
  font-style: italic;
  p {
    margin-bottom: calc(var(--space-xs)/2);
  }
  cite {
    font-size: var(--text-sm);
    font-style: normal;
  }
  blockquote {
    @include last-child-zero-margin;
  }
}


// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
