// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
.form-control {
  padding: 12px 18px;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
  &:focus-visible {
    @include focus-outline-thin;
  }

}

.gform_wrapper {
  &.gravity-theme {
    .gsection {
      border: none !important;
      padding: 0 !important;
      margin-bottom: -.75em;
      h3.gsection_title {
        margin: 0;
        font-size: var(--text-base);
        padding-left: 19px;
      }
    }
    .gform_fields {
      grid-row-gap: var(--space-base) !important;
    }
  }
  input[type=color],
  input[type=date],
  input[type=datetime-local],
  input[type=datetime],
  input[type=email],
  input[type=month],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url],
  input[type=week],
  select,textarea {
    @extend .form-control;
    font-size: var(--input-text-size) !important;
    padding: 12px 18px !important;
  }
  input[type=submit] {
    @extend .btn;
    font-size: var(--input-text-size) !important;
    &.disabled,&.button-disabled,&:disabled {
      pointer-events: none;
      cursor: not-allowed;;
//       text-transform: uppercase;
    }
  }


  .gfield {
    legend {
      @include sr-only;
    }
    &.gfield--type-consent {
      @include slate-black-trans-05;
      padding: var(--space-base);
      font-size: var(--input-text-sm) !important;
      border-radius: var(--border-radius);
      line-height: 1;
      label {
        font-size: var(--text-sm);
        margin: 0 0 0 var(--gap-sm);
      }
    }
  }
  .gfield:not(.gfield--type-consent) {
    label {
      @include sr-only;
    }
  }
}
.form-wrap {
  .gform_wrapper {
    &.gravity-theme {
      .gform_footer {
        margin-bottom: 0;
        input[type=submit] {
          width: 100%;
        }
      }
    }
  }
}
