.page-header {
  padding: var(--space-block) 0 calc(var(--space-block)/2);
  margin-bottom: var(--space-lg);
  .overline {
    margin-bottom: var(--space-xs);
  }
  .page-title {
    margin-bottom: 0;
  }
  &.page-header-image {
    @include page-header-multi;
  }
}
