.wp-full-width {
  @include wp-full-width-no-padding;
}
.vgan-block {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  padding-top: var(--space-block);
  padding-bottom: var(--space-block);
  &:not(.bg-white) {
    &+.vgan-block.bg-white {
      margin-top: calc(var(--space-lg)*-1);
    }
  }
  &+.vgan-block:not(.bg-white) {
    margin-top: calc(var(--space-lg)*-1);
  }
  &.bg-white{
//     padding-top: 0;
    &+.vgan-block.bg-white {
      margin-top: calc(var(--space-lg)*-1);
    }
  }

  .overview,.overview-row {
    @include last-child-zero-margin;
    color: var(--color-body-text-alt);
    font-size: var(--text-lg);
    h2 {
      color: var(--color-body-text);
    }
    &.large-text {
      font-size: var(--text-2xl);
    }
  }
  .overview {
    margin-bottom: calc(var(--space-sm)*2);
  }
}
.page-header {
  &+.vgan-block {
    padding-top: 0;
  }
}
main.main {
//   @include headings-top-spacing;
  &>:first-child {
    &.vgan-block {
      margin-top: 0;
    }
  }
  &>:last-child {
    &.vgan-block {
      margin-bottom: calc(var(--space-lg)*-1);
    }
  }
}
.blog-featured-block {
  .overview-row {
    margin-bottom: var(--space-md);
    .details {
      @include media-breakpoint-up(md) {
        @include last-child-zero-margin;
      }
    }
    .action {
      @include last-child-zero-margin;
      @include media-breakpoint-up(md) {
       justify-content: flex-end;
      }
    }
  }
}
.committee-block {
  .committee {
    margin-top: var(--gap-64);
    .member {
      @include last-child-zero-margin;
      padding-top: var(--space-xs);
      margin-bottom: var(--gap-xl);
      border-top: 1px solid var(--basic-border-color);
      line-height: var(--leading-tight);
      p {
        margin-bottom: var(--gap-2xs);
        &.name {
          font-weight: var(--weight-semibold);
          color: var(--color-black);
        }
        &.company {
          font-size: var(--text-sm);
          color: var(--color-body-text-alt);
        }
      }
    }
  }
  &+.committee-block {
    margin-top: calc(var(--space-lg)*-1.5);
    padding-top: 0;
  }
}
.featured-cta-block {
  .overview {
    margin-bottom: 0;
  }
  .media {
    .embed-responsive {
      border-radius: var(--border-radius-lg);
    }
    figure {
      @include figure-aspect-ratio('1/1');
      margin-bottom: 0;
      border-radius: $border-radius-lg;
    }
  }
  &:nth-child(even of .featured-cta-block) {
    &>.container-fluid {
      &>.row {
        flex-direction: column-reverse;
        @include media-breakpoint-down(sm) {
          .media {
            figure {
              margin-bottom: var(--space-sm);
            }
          }
        }
        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .overview {

      }
      .media {
        &.offset-xl-1 {
          margin-left: 0;
          margin-right: 8.33333333%;
        }
      }
    }
  }
  &:nth-child(odd of .featured-cta-block) {
    @include media-breakpoint-down(sm) {
      .overview {
        margin-bottom: var(--space-sm);
      }
    }
    @include media-breakpoint-up(xl) {
      .overview {
        &.offset-xl-1 {
          margin-left: 0;
          margin-right: 8.33333333%;
        }
      }
      .media {
      }
    }
  }
}
.featured-video-block {
  .video {
    margin-top: var(--space-md);
    .embed-responsive {
      border-radius: var(--border-radius-lg);
    }
  }
}
.form-block {
  .overview {
    a:not(.btn) {
      @include link-underline;
    }
  }
}
.form-columns-block {
  @include media-breakpoint-up(lg) {
    .details {
      padding-right: var(--space-sm);
    }
    aside {
      padding-left: var(--space-sm);
    }
  }
  .form-wrap {
    padding: var(--space-sm);
    border-radius: var(--border-radius-lg);
    h3 {
      margin-bottom: var(--space-sm);
    }
  }
}
.hero-block {
  padding-bottom: 0;
  .overview {
    color: var(--color-body-text);
    margin-bottom: var(--gap-xl);
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .description {
    font-size: var(--text-lg);
    .buttons-list {
      margin-top: var(--gap-xl);
      @include media-breakpoint-down(sm) {
        li {
          display: block;
          .btn {
            width: 100%;
          }
          &+li {
            a {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .media {
    margin-top: var(--gap-xl);
    @include media-breakpoint-up(lg) {
      margin-top: var(--gap-64);
    }
    figure {
      @include figure-aspect-ratio('10/7');
      margin-bottom: 0;
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
    }
  }
}
.logos-block {
  padding-bottom: var(--space-lg);
  .overview {
  }
  .logos {
    .tile {
      margin-bottom: var(--space-sm);
      figure {
        border-radius: var(--border-radius-lg);
        padding: var(--space-sm);
        height: 100%;
        display: grid;
        margin-bottom: 0;
        min-height: 200px;
        place-items: center;
        img,svg {
          max-width: 100%;
          max-height: 200px;
        }
        svg {
          @include transition($link-transition);
          @include svg-color-fill(var(--color-icon));
        }
        a {
          @include hover-full {
            svg {
              @include svg-color-fill(var(--color-icon-hover));
            }
          }
        }
      }
    }

  }
  .logo-marquee {
    .marquee-item {
      margin: 0 32px;
      height: 100px;
      width: auto !important;
      figure {
        display: grid;
        height: 100px;
        place-items: center;
        margin: 0;
        max-width: 200px;
        img,svg {
          max-width: 100%;
          max-height: 100px;
        }
        svg {
          @include transition($link-transition);
          @include svg-color-fill(var(--color-icon));
        }
        a {
          @include hover-full {
            svg {
              @include svg-color-fill(var(--color-icon-hover));
            }
          }
        }
      }
    }
  }
}
.related-posts-block {
  .overview-row {
    margin-bottom: var(--space-lg);
  }
  .details {
    @include last-child-zero-margin;
  }
}
.resource-links-block {
  .overview {
    margin-bottom: var(--space-lg);
  }
  .resource-links {
    ul {
      @include list-normalize;
      border-top: 1px solid var(--color-body-text);
      li {
        border-bottom: 1px solid var(--color-body-text);
        a {
          @include link-no-underline;
          color: var(--color-body-text);
          padding: var(--space-base) var(--gap-xs);
          font-weight: var(--weight-medium);
          @include media-breakpoint-down(sm) {
            flex-direction: column;
          }
          span {
            &.info {
              font-size: var(--text-2xl);
              [class*="fa-"] {
                min-width: var(--space-base);
                margin-right: var(--space-base);
                text-align: center;
                @include media-breakpoint-down(sm) {
                  margin-right: var(--space-xs);
                }
              }
            }
            &.link {
              [class*="fa-"] {
                margin-left: var(--space-xs);
              }
              @include media-breakpoint-down(sm) {
                margin-top: var(--gap-xs);
                text-align: right;
              }
            }
          }
          @include hover-full {
            background-color: var(--color-body-hover);
          }
        }
      }
    }
  }
}
.testimonials-block {
  margin-bottom: var(--space-md);
  .testimonial-row {
//     justify-content: center;
    .testimonial {
      margin-bottom: var(--space-sm);
      blockquote {
        @include tile-basic;
        margin-bottom: 0;
        font-size: var(--text-2xl);
        font-weight: var(--weight-semibold);
        font-style: normal;
        cite {
          margin: var(--space-sm) 0 0 0;
          font-style: normal;
          font-size: var(--text-sm-md);
          font-weight: var(--weight-normal);
          .attribution {
            @include d-flex;
            align-items: center;
            .headshot {
              figure {
                @include figure-aspect-ratio('1/1');
                margin-bottom: 0;
                width: 56px;
                border-radius: 50%;
                margin: 0 var(--space-xs) 0 0;
              }
            }
            .info {
              line-height: var(--leading-tight);
              p {
                margin-bottom: 0;
                &.name {
                  font-weight: var(--weight-semibold);
                }
              }
            }
          }
          .company {
            @include d-flex;
            max-width: 35%;
            align-items: center;
            border-left: 1px solid var(--color-black);
            .logo {
              margin: 0;
              img {
                width: 100%
              }
            }
          }



/*
          .headshot {
            align-items: center;
            margin: 0 var(--space-xs) 0 0;
            figure {
              @include figure-aspect-ratio('1/1');
              margin-bottom: 0;
              width: 56px;
              border-radius: 50%;
              margin: 0;
            }
          }
          .logo {
            align-items: center;
            padding-left: var(--space-xs);
            figure {
              margin-bottom: 0;
            }
          }
*/
        }
      }
    }
  }
}
.tile-links-block {
  padding-bottom: var(--gap-96);
  .tile-links {
    margin-top: var(--gap-64);
    .tile-link {
      margin-bottom: var(--space-sm);
      a,article {
        display: block;
        padding: var(--space-sm);
        border-radius: $border-radius-lg;
        height: 100%;
        line-height: var(--leading-tight);
        &>span {
          @include d-flex;
          justify-content: space-between;
          align-items: flex-start;
          &.icon {
            align-items: center;
            margin-bottom: var(--space-md);
            [class*="fa-"] {
              @include transition($link-transition);
              color: var(--color-icon);
              font-size: var(--text-5xl);
            }
            .step {
              @include transition($link-transition);
              margin-left: var(--space-xs);
              padding: 4px 12px;
              font-size: var(--text-sm);
              border: 1px solid var(--color-icon);
              border-radius: 50px;
              text-transform: uppercase;
              font-weight: var(--weight-semibold);
            }
          }
          &.text {
            font-size: var(--text-2xl);
            font-weight: var(--weight-medium);
            @include media-breakpoint-up(xl) {
              font-size: var(--text-2xl);
            }
            [class*="fa-"] {
              margin-left: var(--space-base);
              font-size: var(--text-3xl);
            }
          }
          &.description {
            @include last-child-zero-margin;
            border-bottom: 1px solid transparent;
            font-size: var(--text-base);
            margin-top: var(--space-base);
            line-height: var(--leading-normal);
/*
            width: 90%;
            color: var(--color-body-text-alt);
*/
          }
        }
      }
      a {
        @include link-no-underline;
        @include hover-full {
          background-color: var(--color-body-hover);
          color: var(--color-body-text);
          &>span {
            &.icon {
              [class*="fa-"] {
                color: var(--color-body-text);
              }
              .step {
                background-color: var(--color-body-text);
                border-color: var(--color-body-text);
                color: var(--color-body-bg);
              }
            }
          }
        }
      }
    }
  }
}
