.articles-grid {
  @include tile-grid;
  article.hentry {
    a.article-link {
      &>header {
        .entry-title {
        }
        .category {
        }
      }
    }
  }
}
.blog {
  section.featured-article {
    @include page-header-multi;
    h1 {
      a {
        @include link-no-underline;
        @include link-article-color;

      }
    }
  }
}
.news-grid-title {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-md);
  .details {
    @include last-child-zero-margin;

  }
}
.single-post {
  .hentry {
    &>header {
      @include page-header-multi;
    }
    footer {
      padding-top: calc(var(--space-sm)*2);
      .social {
        p {
          font-weight: var(--weight-semibold);
          margin: 0 0 var(--space-xs);
          font-size: var(--text-lg);
        }
      }
      .tag-wrapper {
        text-align: right;
        .tags {
          @include list-pills;
        }
      }
      hr {
        margin: var(--space-md) 0;
      }
      .author-info {
        .avatar {
          margin-right: var(--space-xs);
          figure {
            @include figure-aspect-ratio('1/1');
            width: 56px;
            border-radius: 50%;
            margin: 0;
          }
        }
        .name {
          .author {
            margin: 0;
            font-weight: var(--weight-semibold);
          }
          .title {

          }
        }
      }
      @include media-breakpoint-down(sm) {
        &>.row {
          flex-direction: column-reverse;
          .tag-wrapper {
            text-align: inherit;
            margin-bottom: var(--space-sm);
            .tags {
              li {
                margin-right: var(--gap-xs);
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
