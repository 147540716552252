footer.content-info {
  padding: var(--gap-128) 0 var(--gap-64);
  background-image: url(../images/logo-footer.png);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: right 28px;
  @include media-breakpoint-up(md) {
    background-size: 80%;
    background-position: right 128px;
  }
  nav {
    ul {
      @include list-normalize;
      margin-bottom: calc(var(--space-sm)*2.25);
      li {
        margin-bottom: calc(var(--space-xs)*1.25);
        a {
          @include link-no-underline;
          @include link-color-reverse;
          font-weight: var(--weight-medium);
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.button {
          a {
            display: inline-block;
          }
        }
      }
    }
    &.ancillary {
      ul {
        margin-bottom: 0;
        font-size: var(--text-sm);
        li {
          margin-bottom: var(--space-base);
          a {
            @include link-underline;
            @include white-60;
            @include hover-full {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }
  .copyright {
    @include white-60;
    margin: var(--space-md) 0 0;
    font-size: var(--text-sm);
  }
}
