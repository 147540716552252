// Grid system
.container-fluid {
  max-width: $container-fluid-max !important;
}
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
body {
  main.main {
    @include main-column-width-full;
    padding-bottom: var(--space-lg);
  }
  &:not(.blog):not(.archive) {
    main.main {
      @include main-column-width-wide;
    }
  }
}
